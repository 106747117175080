import React, { useState, useContext } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';

import { UserContext, update_player_silver } from "../../Providers/WSProvider";
import { TextField } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { validateNumber } from '../../Helpers/Numbers';


const useStyles = makeStyles((theme) => ({
	filledColor: {
		color: '#fff'
	}
}));


const PlayerList = (props) => {
	const context = useContext(UserContext);
	const guild_members = context['guild_members'];
	const guild = context['active_guild'];

	const [search, setSearch] = useState("");
	const [dialogPlayerOpen, setDialogPlayerOpen] = useState(false);

	const [qtdePrataMovimentacao, setQtdePrataMovimentacao] = useState("");
	const [acaoPrataMovimentacao, setAcaoPrataMovimentacao] = useState("add");
	const [playerEditarPrata, setPlayerEditarPrata] = useState({ id: null, username: '', prataAtual: 0 });


	const closeDialogPlayer = function () {
		setDialogPlayerOpen(false);
	}

	const abrirDialogPlayerPrata = function (member) {
		setPlayerEditarPrata({
			id: member.userID,
			username: member.username,
			prataAtual: member.silver_in_guild,
		});
		setQtdePrataMovimentacao('');
		setAcaoPrataMovimentacao("add");
		setDialogPlayerOpen(true);
	}

	const movimentarPrata = function () {
		setDialogPlayerOpen(false);
		
		if(qtdePrataMovimentacao == ''){
			return;
		}
		context.setLoading(true);
		update_player_silver(playerEditarPrata.id, guild.guildID, acaoPrataMovimentacao, parseInt(qtdePrataMovimentacao.replaceAll('.', '')));
	}


	

	const classes = useStyles();


	return (
		<div className="guildPlayerList">
			<TextField
				className="textfield"
				label="Pesquisar"
				id="playerSearch"
				name="playerSearch"
				value={search}
				style={{ color: '#fff', width: '100%' }}
				onChange={(e) => {
					setSearch(e.target.value);
				}}
				variant="filled"
				placeholder=""
				InputProps={{
					endAdornment: <InputAdornment position="end">
						<SearchIcon style={{ fill: "#c9c9c9" }} />
					</InputAdornment>,
					color: 'primary',
					className: classes.filledColor,
				}}
				InputLabelProps={{
					style: { color: '#c9c9c9' },
				}}
			/>
			<List component="nav">
				{guild_members.filter((member) => member.username.toUpperCase().includes(search.toUpperCase())).map((member) => {
					return (
						<ListItem style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace:'nowrap' }} button onClick={function () { abrirDialogPlayerPrata(member); }}>
							<ListItemIcon>
								<img className="profileGuildPic" src={member.avatar} />
							</ListItemIcon>
							<ListItemText primary={<span title={member.username} className={(member.system?' system-user ':'')+"textEllipisis"}>{member.username}</span>} secondary={member.silver_in_guild.toLocaleString() + " prata"}></ListItemText>
						</ListItem>
					)
				})}
			</List>

			<Dialog open={dialogPlayerOpen} onClose={closeDialogPlayer} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Movimentar Prata do jogador {playerEditarPrata.username}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<p>Atualmente o jogador possui {playerEditarPrata.prataAtual} de prata.</p>
					</DialogContentText>
					<Select
						labelId="acaoMovPratalabel"
						id="acaoMovPrata"
						value={acaoPrataMovimentacao}
						className="selectInput"
						onChange={(e) => {
							setAcaoPrataMovimentacao(e.target.value);
						}}
						InputLabelProps={{
							style: { color: '#c9c9c9' },
						}}
						InputProps={{
							className: classes.filledColor,
						}}
					>
						<MenuItem value={"add"}>Adicionar prata</MenuItem>
						<MenuItem value={"remove"}>Remover prata</MenuItem>
					</Select>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Quantidade de prata"
						onChange={(e) => {
							if (validateNumber(e.target.value.replaceAll('.', ''))) {
								setQtdePrataMovimentacao(parseInt(e.target.value.replaceAll('.', '')).toLocaleString());
							}
							if (e.target.value == '') {
								setQtdePrataMovimentacao('');
							}
						}}
						fullWidth
						value={qtdePrataMovimentacao}
						InputLabelProps={{
							style: { color: '#c9c9c9' },
						}}
						InputProps={{
							className: classes.filledColor,
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialogPlayer} color="primary" variant="contained">
						Cancelar
					</Button>
					<Button onClick={movimentarPrata} color="primary" variant="contained">
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default PlayerList;