import Button from '@material-ui/core/Button';
import React, { useState, useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

import { UserContext, set_active_guild } from "../Providers/WSProvider";

const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
	drawer: {
    width: drawerWidth
  },
  // Styles for the `Paper` component rendered by `Drawer`.
  drawerPaper: {
    width: "inherit",
	background: "#2f3136",
	color: "#fff",
  },
}));


const MainMenu = (props) => {
	const context = useContext(UserContext);
	const user = context['user'];
	const ready = context['ready'];
	const setLoading = context['setLoading'];

	const classes = useStyles();

	const selectGuild = function(guild_id){
		set_active_guild(guild_id);
		setLoading(true);
		//alert(guild_id);
	}

	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor="left"
		>
			<p className="bigText pageTitle">Albion Apostas</p>
			<div className="menuProfile">
				<img className="profilePic" src={user.avatar} />
				<span>{user.username}</span>
				<br />
				<Button variant="contained" color="primary" className="btnSair" onClick={function () { window.location.reload(); }}>Sair</Button>
			</div>
			<List component="nav">
				{user.guilds.map((guild) => {
					return (
						<ListItem button onClick={function(){selectGuild(guild.id)}}>
							<ListItemIcon>
								<img className="profileGuildPic" src={guild.icon} />
							</ListItemIcon>
							<ListItemText primary={guild.name} />
						</ListItem>
					)
				})}
			</List>

		</Drawer>
	);
}

export default MainMenu;