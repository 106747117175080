'use-strict';

import React, { Component, createContext } from "react";


export const UserContext = createContext({ user: null, ready: false, token: null });
var ws = null;
var session_token = null;

export function set_active_guild(guild_id) {
    ws.send(JSON.stringify({
        token: session_token,
        action: 'SET_ACTIVE_GUILD',
        data: {
            guild_id: guild_id,
        },
    }));
}

export function update_player_silver(player_id, guild_id, acao, silver) {
    ws.send(JSON.stringify({
        token: session_token,
        action: 'UPDATE_PLAYER_SILVER',
        data: {
            player_id: player_id,
            guild_id: guild_id,
            acao: acao,
            silver: silver
        },
    }));
}


export function set_fight(team1, team2, silverCost, vencedorLuta, status, guild_id) {
    ws.send(JSON.stringify({
        token: session_token,
        action: 'SET_FIGHT',
        data: {
            team1: team1,
            team2: team2,
            silverCost: silverCost,
            vencedorLuta: vencedorLuta,
            status: status,
            guild_id: guild_id
        },
    }));
}

export function new_bet(team, guild_id){
    ws.send(JSON.stringify({
        token: session_token,
        action: 'NEW_BET',
        data: {
            team: team,
            guild_id: guild_id
        },
    }));
}

class WSProvider extends Component {  // componente para que todas as telas possam acessar o usuário logado (caso tenha um)
    state = {
        user: null,     // state que salva os dados do usuário autenticado (caso tenha)
        ready: false,   // state para telas verificarem se já fez a autenticação no ws
        login_error: null,
        token: null,
        loading: false,
        active_guild: null,
        silver_in_guild: 0,
        cur_fight: null,
        bets: [],
        guild_members: [],
        alert_text: "",
        setAlertText: null,
    };



    componentDidMount = async () => {
        var self = this;

        this.setLoading = function (value) {
            self.setState({ loading: value });
        }
        this.setAlertText = function (value) {
            self.setState({ alert_text: value });
        }


        const code = this.props.discord_code;
        const wsserver='wss://albionapostas.ragnarokmvptimer.com:3000';
        if(window.env=='dev'){
            wsserver="ws://" + window.location.hostname + ":8081";
        }

        ws = new WebSocket(wsserver);
        window.ws = ws;
        ws.onopen = function () {
            console.log('-- WS ABERTO --');
            ws.send(JSON.stringify({
                action: 'LOGIN',
                code: code,
                url: "http://" + window.location.host,
            }));
        };

        ws.onmessage = function (message) {
            console.log('server message:', message);
            window.mensagem = message;
            try {
                message = JSON.parse(message.data);
            } catch (ex) {
                console.log('INVALID PACKAGE (1)');
                return;
            }
            if (typeof message.action == 'undefined') {
                return;
            }

            switch (message.action) {
                case 'LOGGEDIN':
                    console.log('user', message.data.user);
                    session_token = message.data.token;
                    self.setState({ user: message.data.user, ready: true, token: message.data.token });
                    break;
                case 'LOGIN_FAIL':
                    self.setState({ login_error: message.data.msg });
                    break;
                case 'UPDATE_ACTIVE_GUILD':
                    var new_state = {
                        silver_in_guild: self.state.silver_in_guild,
                        active_guild: self.state.active_guild,
                        cur_fight: self.state.cur_fight,
                        bets: self.state.bets,
                        guild_members: self.state.guild_members,
                    };

                    if (message.data.infos.includes('silver_in_guild')) {
                        new_state.silver_in_guild = message.data.silver_in_guild;
                    }
                    if (message.data.infos.includes('guild')) {
                        new_state.active_guild = message.data.guild;
                    }
                    if (message.data.infos.includes('cur_fight')) {
                        new_state.cur_fight = message.data.cur_fight;
                    }
                    if (message.data.infos.includes('cur_bets')) {
                        new_state.bets = message.data.cur_bets;
                    }
                    if (message.data.infos.includes('guild_members')) {
                        new_state.guild_members = message.data.guild_members;
                    }
                    
                    self.setState(new_state);
                    break;
                case 'UPDATE_SILVER_IN_GUILD':
                    self.setState({ silver_in_guild: message.data.silver_in_guild })
                    break;
                case 'UPDATE_GUILD_MEMBERS':
                    self.setState({ guild_members: message.data.guild_members });
                    break;
                case 'ALERT_MESSAGE':
                    self.setState({
                        alert_text: message.data.text
                    });
                    break;
            }

            if (typeof message.hide_loading != 'undefined' && message.hide_loading == true) {
                self.setState({ loading: false });
            }
        };

        /*
        auth.onAuthStateChanged(async userAuth => {
            const user = (userAuth);
            this.setState({ user: user, ready: true });
        });
        */


    };

    render() {
        const {
            user,
            ready,
            login_error,
            token,
            loading,
            setLoading,
            active_guild,
            silver_in_guild,
            cur_fight,
            bets,
            guild_members,
            alert_text,
            setAlertText,
        } = this.state;

        return (
            <UserContext.Provider value={{
                user: user,
                ready: ready,
                login_error: login_error,
                token: token,
                loading: loading,
                setLoading: this.setLoading,
                active_guild: active_guild,
                silver_in_guild: silver_in_guild,
                cur_fight: cur_fight,
                bets: bets,
                guild_members: guild_members,
                alert_text: alert_text,
                setAlertText: this.setAlertText,

            }}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default WSProvider;