export function numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


export function validateNumber(number) {
    if (/^([0-9]+?)$/.test(number)) {
        return (true)
    }
    return (false)
}