import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { UserContext, set_fight, new_bet } from "../../Providers/WSProvider";
import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { validateNumber } from '../../Helpers/Numbers';


const useStyles = makeStyles((theme) => ({
    filledColor: {
        color: '#fff'
    }
}));


const Fight = (props) => {
    const context = useContext(UserContext);
    const cur_fight = context['cur_fight'];
    const bets = context['bets'];
    const guild = context['active_guild'];
    const silver_in_guild = context['silver_in_guild'];

    const bets_team1 = bets.filter((bet) => bet.team == '1');
    const bets_team2 = bets.filter((bet) => bet.team == '2');

    const [nomePlayer1, setNomePlayer1] = useState("");
    const [nomePlayer2, setNomePlayer2] = useState("");
    const [qtdePrataAposta, setQtdePrataAposta] = useState("10.000");
    const [status, setStatus] = useState("ABERTO");

    const [apostarEm, setApostarEm] = useState(null);

    const [vencedorLuta, setVencedorLuta] = useState('1');


    const [dialogSetFight, setDialogSetFight] = useState(false);
    const [dialogEncerrarAposta, setDialogEncerrarAposta] = useState(false);

    const abrirDialogSetFight = function (member) {
        if (cur_fight != null) {
            setNomePlayer1(cur_fight.team1.name);
            setNomePlayer2(cur_fight.team2.name);
            setQtdePrataAposta(cur_fight.silverCost.toLocaleString());
            setStatus(cur_fight.status);
        } else {
            setNomePlayer1("");
            setNomePlayer2("");
            setQtdePrataAposta("10.000");
            setStatus("ABERTO");
        }
        setDialogSetFight(true);
    }

    const closeDialogSetFight = function () {
        setDialogSetFight(false);
    }

    const btnSetFight = function () {
        if (nomePlayer1 == '') {
            return;
        }
        if (nomePlayer2 == '') {
            return;
        }

        setDialogSetFight(false);

        context.setLoading(true);
        set_fight(nomePlayer1, nomePlayer2, parseInt(qtdePrataAposta.replaceAll('.', '')), null, status, guild.guildID);
    }

    const encerrarLuta = function () {
        setDialogEncerrarAposta(false);

        context.setLoading(true);
        set_fight(cur_fight.team1.name, cur_fight.team2.name, cur_fight.silverCost, vencedorLuta, "ENCERRADO", guild.guildID);
    }

    const apostar = function () {
        if (silver_in_guild < cur_fight.silverCost) {
            setApostarEm(null);
            context.setAlertText("Você não tem prata suficiente para efetuar essa aposta.");
            return;
        }
        new_bet(apostarEm == 'p1' ? 1 : 2, guild.guildID);
        setApostarEm(null);
        context.setLoading(true);
    }

    const classes = useStyles();

    const content_set_fight = (
        <Dialog open={dialogSetFight} onClose={function () { closeDialogSetFight(); }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Cadastrar uma nova luta</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Insira os dados para a luta
                </DialogContentText>
                <TextField
                    max
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome do Time/Jogador 1"
                    onChange={(e) => {
                        setNomePlayer1(e.target.value)
                    }}
                    fullWidth
                    value={nomePlayer1}
                    InputLabelProps={{
                        style: { color: '#c9c9c9' },
                    }}
                    inputProps={{
                        className: classes.filledColor,
                        maxLength: 35
                    }}
                />

                <TextField
                    margin="dense"
                    id="name"
                    label="Nome do Time/Jogador 2"
                    onChange={(e) => {
                        setNomePlayer2(e.target.value)
                    }}
                    fullWidth
                    value={nomePlayer2}
                    InputLabelProps={{
                        style: { color: '#c9c9c9' },
                    }}
                    inputProps={{
                        className: classes.filledColor,
                        maxLength: 35
                    }}
                />

                <TextField
                    margin="dense"
                    id="name"
                    label="Valor minimo da aposta"
                    onChange={(e) => {
                        if (validateNumber(e.target.value.replaceAll('.', ''))) {
                            setQtdePrataAposta(parseInt(e.target.value.replaceAll('.', '')).toLocaleString());
                        }
                        if (e.target.value == '') {
                            setQtdePrataAposta('');
                        }
                    }}
                    fullWidth
                    value={qtdePrataAposta}
                    InputLabelProps={{
                        style: { color: '#c9c9c9' },
                    }}
                    inputProps={{
                        className: classes.filledColor,
                        maxLength: 10
                    }}
                />

                <TextField
                    margin="dense"
                    label="Status"
                    select
                    id="acaoMovPrata"
                    value={status}
                    className="selectInput"
                    style={{ marginTop: '15px' }}
                    onChange={(e) => {
                        setStatus(e.target.value);
                    }}
                    InputLabelProps={{
                        style: { color: '#c9c9c9' },
                    }}
                    InputProps={{
                        className: classes.filledColor,
                    }}
                >
                    <MenuItem value={"ABERTO"}>Apostas Liberadas</MenuItem>
                    <MenuItem value={"FECHADO"}>Apostas Bloqueadas</MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={function () { closeDialogSetFight() }} color="primary" variant="contained">
                    Cancelar
                </Button>
                <Button onClick={function () { btnSetFight() }} color="primary" variant="contained">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );



    if (cur_fight == null) {
        return (
            <div>
                <h3>Não há nenhuma luta ocorrendo no momento</h3>
                {
                    guild.permission_to_bot ?
                        (
                            <div>
                                <Button color="primary" variant="contained" onClick={() => { abrirDialogSetFight() }}>Configurar nova luta</Button>
                                {content_set_fight}
                            </div>
                        ) : <div></div>
                }

            </div>
        );
    }


    const content_confirm_bet = (
        <Dialog open={apostarEm == 'p1' || apostarEm == 'p2'} onClose={function () { setApostarEm(null) }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Atenção</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Você está prestes a apostar {cur_fight.silverCost.toLocaleString()} em
                    {apostarEm == 'p1' ? ' ' + cur_fight.team1.name : ''}
                    {apostarEm == 'p2' ? ' ' + cur_fight.team2.name : ''}
                    <br />
                    Deseja mesmo continuar?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={function () { setApostarEm(null) }} color="primary" variant="contained">
                    Cancelar
                </Button>
                <Button onClick={apostar} color="primary" variant="contained">
                    Apostar
                </Button>
            </DialogActions>
        </Dialog>
    );

    const content_encerrar_aposta = (
        <Dialog open={dialogEncerrarAposta} onClose={function () { setDialogEncerrarAposta(false) }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Atenção</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ao clicar no botão abaixo você estará encerrando a aposta atual e distribuindo automaticamente a prata aos vencedores.<br></br>
                    Para prosseguir selecione o vencedor abaixo e clice no botão encerrar.
                </DialogContentText>

                <TextField
                    margin="dense"
                    label="Vencedor"
                    select
                    id="acaoMovPrata"
                    value={vencedorLuta}
                    className="selectInput"
                    style={{ marginTop: '15px' }}
                    onChange={(e) => {
                        setVencedorLuta(e.target.value);
                    }}
                    InputLabelProps={{
                        style: { color: '#c9c9c9' },
                    }}
                    InputProps={{
                        className: classes.filledColor,
                    }}
                >
                    <MenuItem value={1}>{cur_fight.team1.name}</MenuItem>
                    <MenuItem value={2}>{cur_fight.team2.name}</MenuItem>
                </TextField>

            </DialogContent>
            <DialogActions>
                <Button onClick={function () { setDialogEncerrarAposta(false) }} color="primary" variant="contained">
                    Cancelar
                </Button>
                <Button onClick={encerrarLuta} color="primary" variant="contained">
                    Encerrar Luta
                </Button>
            </DialogActions>
        </Dialog>
    );

    // tem uma fight ocorrendo. retornar ela
    return (
        <div>
            {
                guild.permission_to_bot ?
                    (
                        <div style={{ marginTop: 25 }}>
                            {content_set_fight}
                            <Button onClick={function () { abrirDialogSetFight() }} color="primary" variant="contained">
                                Editar
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={function () { setDialogEncerrarAposta(true) }} color="primary" variant="contained">
                                Encerrar
                            </Button>
                        </div>
                    ) : ''
            }

            {content_confirm_bet}
            {content_encerrar_aposta}

            <Grid container>
                <Grid xs style={{ width: 'calc(50% - 350px)' }}>
                    <h3>{cur_fight.team1.name}</h3>
                    <div className="divApostas">
                        <List component="nav">
                            {bets_team1.map((bet) => {
                                return (
                                    <ListItem style={{ display: 'inline-block', textAlign: 'center', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} button>
                                        - {bet.player.username} - {bet.amount.toLocaleString()}
                                    </ListItem>
                                )
                            })}
                        </List>
                        {bets_team1.length === 0 ? 'Nenhuma aposta' : ''}
                    </div>
                    <p>Total apostado: {cur_fight.totalSilverTeam1.toLocaleString()}</p>
                    {cur_fight.status == 'ABERTO' ? (
                        <Button onClick={function () { setApostarEm("p1") }} color="primary" variant="contained">
                            Apostar {(cur_fight.silverCost.toLocaleString())}
                        </Button>
                    ) : ''}
                </Grid>
                <Grid xs>
                    <h3>VS</h3>
                </Grid>
                <Grid xs style={{ width: 'calc(50% - 350px)' }}>
                    <h3>{cur_fight.team2.name}</h3>
                    <div className="divApostas">
                        <List component="nav">
                            {bets_team2.map((bet) => {
                                return (
                                    <ListItem style={{ display: 'inline-block', textAlign: 'center', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} button>
                                        - {bet.player.username} - {bet.amount.toLocaleString()}
                                    </ListItem>
                                )
                            })}
                        </List>
                        {bets_team2.length === 0 ? 'Nenhuma aposta' : ''}
                    </div>
                    <p>Total apostado: {cur_fight.totalSilverTeam2.toLocaleString()}</p>
                    {cur_fight.status == 'ABERTO' ? (
                        <Button onClick={function () { setApostarEm("p2") }} color="primary" variant="contained">
                            Apostar {(cur_fight.silverCost.toLocaleString())}
                        </Button>
                    ) : ''}
                </Grid>
            </Grid>

        </div >
    );
}

export default Fight;