import React, { useState, useContext } from 'react';

import PlayerList from './PlayerList';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { UserContext, set_active_guild } from "../../Providers/WSProvider";
import Fight from './Fight';




const ActiveGuild = (props) => {
	const context = useContext(UserContext);
	const guild = context['active_guild'];
	const silver_in_guild = context['silver_in_guild'];
	const [showUserList, setShowUserList] = useState(false);


	return (
		<div style={{position: 'relative'}}>
			<div className="guildTopBar" /*style={{ backgroundImage: 'url(https://cdn.discordapp.com/icons/276859108299112451/dad5cbb07be9d7857ff8873f82727f15.png?size=128)' }}*/>
				<h3>{guild['guildName']}<br />{guild['displayName']} - {silver_in_guild.toLocaleString()} prata</h3>
			</div>

			{ guild.permission_to_bot && !showUserList ? (
				<div style={{position: 'absolute', left: 0, top: 'calc(10vh + 20px)'}}>
					<Button color="primary" variant="contained" onClick={function(){setShowUserList(true)}}  >
						<NavigateNextIcon/>
					</Button>
				</div>
				) : ''}

			<Grid container justifyContent="left">
				{ guild.permission_to_bot ? (
				<Grid xs={2} style={{position: 'relative', display: showUserList ? 'inline-block' : 'none'}}>
					<div style={{position: 'absolute', right: -50, top: '20px'}}>
						<Button color="primary" variant="contained" onClick={function(){setShowUserList(false)}}  >
							<NavigateBeforeIcon/>
						</Button>
					</div>
					<PlayerList></PlayerList>
				</Grid>
				):''}
				<Grid xs>
					<Fight></Fight>
				</Grid>
			</Grid>

		</div>
	);
}

export default ActiveGuild;