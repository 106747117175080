import Button from '@material-ui/core/Button';
import React, { useState, useContext } from 'react';
import logoDiscord from '../Assets/Images/logo-discord-white.png';

const Unauth = () => {
    const loginWithDiscord = function(){
        window.location = 'https://discord.com/api/oauth2/authorize?client_id=869403727868624906&redirect_uri=http%3A%2F%2F'+window.location.hostname+(window.env=='prod'?'':'%3A3000')+'&response_type=code&scope=identify%20guilds';
    }

    return (
        <div className="form">
           <Button variant="contained" color="primary" onClick={loginWithDiscord}><img className="btn-icon-image" src={logoDiscord}/> Fazer Login Com Discord</Button>
        </div>
    );
}

export default Unauth;