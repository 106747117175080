import './App.css';

import Unauth from './Components/Unauth';
import Home from './Components/Home';
import WSProvider from './Providers/WSProvider';

function App() {
  const url = new URL(window.location.href);
  const discord_auth_code = url.searchParams.get('code');
  setTimeout(function () {
    window.history.pushState('/', '/', '/');
  }, 1000);
  if (discord_auth_code === null) {
    return (
      <div className="App">
        <header className="App-header">
          <Unauth></Unauth>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <WSProvider discord_code={discord_auth_code}>
            <Home discord_code={discord_auth_code}></Home>
        </WSProvider>
      </div>
    );
  }
}

export default App;
