import React, { useState, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from "../Providers/WSProvider";

import MainMenu from './MainMenu';
import ActiveGuild from './Guild/ActiveGuild';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
	appContent: {
		// https://github.com/philipwalton/flexbugs#flexbug-17
		flex: "1 1 100%",
		maxWidth: "100%",
		paddingTop: 0, // equal to AppBar height + 16px
		margin: "0 auto",
		// Set the max content width for each breakpoint
		// Content will be centered in the space to the right/left of drawer


	}
}));



const Home = (props) => {
	/*
	const ws = useSelector((state) => state.socket.ws);
	const user = props.user; //useSelector((state) => state.socket.user);
	const ready = props.ready; //useSelector((state) => state.socket.ready);
	const login_error = props.login_error; //useSelector((state) => state.socket.login_error);
	const dispatch = useDispatch();
	*/
	const context = useContext(UserContext);
	const user = context['user'];
	const ready = context['ready'];
	const login_error = context['login_error'];
	const loading = context['loading'];
	const active_guild = context['active_guild'];
	const alert_text = context['alert_text'];
	const closeAlertText=function(){
		context['setAlertText']('');
	};
	const classes = useStyles();

	if (login_error != null) {
		return (
			<div className="App">
				<header className="App-header">
					<div className="form">
						<p>{login_error}</p>
					</div>
				</header>
			</div>
		);
	}

	if (!ready || loading) {
		return (
			<div className="App">
				<header className="App-header">
					<div className="form">
						<LinearProgress color="primary" />
						<p>Carregando, aguarde</p>
					</div>
				</header>
			</div>
		);
	} else {
		var main_content = (<div><p className="bigText">Bem vindo, {user.username}</p>
			<p>Para começar clique no servidor que gostaria de apostar na lista ao lado</p></div>);
		if (active_guild != null) {
			main_content = (<ActiveGuild></ActiveGuild>);
		}
		return (
			<div className="app">
				<div className={classes.root}>
					<MainMenu />
					<main className={classes.appContent}>
						{main_content}
					</main>
					<Dialog open={alert_text != ''} onClose={closeAlertText} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Atenção</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{alert_text}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={closeAlertText} color="primary" variant="contained">
								OK
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		);
	}
}

export default Home;